import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Layout from 'layout';
import ArticlePreview from 'components/blog/blogArticlePreview';

import { posts } from 'data/posts';

const Blog = () => {
  const renderArticles = () => {
    if (!posts || posts.length === 0) return false;

    const list = (arr) =>
      map(arr, (el) => {
        return (
          <li key={el.img} className="col-12 col-md-6 col-lg-4">
            {' '}
            <ArticlePreview key={el.link} {...el} />{' '}
          </li>
        );
      });

    const filteredList = filter(posts, (i) => i.group !== 'updates');

    return (
      <section className="blog">
        <div className="container">
          <h3>
            Articles <span>({filteredList.length})</span>
          </h3>
          <ul className="row mb-3 feature-list feature-list-sm">{list(filteredList)}</ul>
        </div>
      </section>
    );
  };

  return (
    <Layout metaTitle="Articles | Blog" canonical="/blog/articles/" className="blog">
      {renderArticles()}
    </Layout>
  );
};

export default Blog;
